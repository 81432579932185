import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

interface Import {
  file_url: string;
  total_added: number;
  total_updated: number;
  total_failed: number;
  added: string;
}

@Component({
  name: 'ImportView',
  computed: {
    ...mapState('registration', ['pastImports'])
  }
})
export default class ImportView extends GtrSuper {
  data () {
    return {
      loading: false,
      imports: {
        loading: false,
        items: [],
        headers: [
          {
            text: 'File Name',
            align: 'start',
            sortable: false,
            value: 'file_url'
          }, {
            text: 'Added Records',
            align: 'start',
            sortable: false,
            value: 'totals.total_added'
          }, {
            text: 'Updated Records',
            align: 'start',
            sortable: false,
            value: 'totals.total_updated'
          }, {
            text: 'Failures',
            align: 'start',
            sortable: false,
            value: 'totals.total_failed'
          }, {
            text: 'Status',
            align: 'start',
            sortable: false,
            value: 'status'
          }, {
            text: 'Added',
            align: 'start',
            sortable: true,
            value: 'created_at'
          }
        ]
      }
    }
  }

  async mounted () {
    await this.fetchPastImports()
  }

  private async fetchPastImports () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        dataType: 'participants'
      }
      const response = await this.$store.dispatch('registration/getPastImports', payload)
      this.$data.imports.items = response.data
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleRowClick (item) {
    this.$router.push({
      name: 'level-two.event.attendees.import.edit',
      params: {
        event_uuid: this.$route.params.event_uuid,
        import_uuid: item.uuid
      }
    })
  }

  handleGoBackToParticipants () {
    this.$router.push({
      name: 'level-two.event.attendees',
      params: {
        event_uuid: this.$route.params.event_uuid
      }
    })
  }

  async onFileUploadChange (files: File[]) {
    try {
      this.$data.loading = true
      const payload = {
        fileData: files[0],
        company_uuid: this.$route.params.company_uuid,
        data: {
          event_uuid: this.$route.params.event_uuid,
          data_type: 'participants'
        }
      }
      const response = await this.$store.dispatch('registration/importFile', payload)
      this.$router.push({ name: 'level-two.event.attendees.import.edit', params: { event_uuid: this.$route.params.event_uuid, import_uuid: response.data.uuid } })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async downloadSample () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        type: 'participants'
      }
      const response = await this.$store.dispatch('registration/getParticipantImportSample', payload)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
